import bmwxvBanner from "../assets/res/projects/bmwxv_project_card.png";
import privateBanner from "../assets/res/projects/private_project_card.png";
import stepitBanner from "../assets/res/projects/stepit_project_card.png";
import deliveryboysBanner from "../assets/res/projects/deliveryboys_project_card.png";
import novelscraperBanner from "../assets/res/projects/novelscraper_project_card.png";
import grevBanner from "../assets/res/projects/grev_project_card.png";
import ritBanner from "../assets/res/projects/rit_project_card.png";
import evtBanner from "../assets/res/projects/evt_project_card.png";
import hyperganicBanner from "../assets/res/projects/hyperganic_project_card.png";
import bigGameLeagueBanner from "../assets/res/projects/biggameleague_project_card.png"
import boonBanner from "../assets/res/projects/boon_project_card.png"
import tammBanner from "../assets/res/projects/tamm_project_card.png"
import { AWSBadge, AngularBadge, AstroBadge, BlenderBadge, DjangoBadge, ElectronBadge, FirebaseBadge, HerokuBadge, IllustratorBadge, LinuxBadge, NextJsBadge, PhotoshopBadge, PythonBadge, ReactBadge, ReactNativeBadge, RubyOnRailsBadge, ThreeJsBadge, TwitchBadge, UnityBadge, VercelBadge } from "./projectBadges";

export type projectType = {
    title: string,
    org: string,
    time?: string,
    date: Date,
    badges?: (() => JSX.Element)[],
    description: string,
    banner: string,
    public: boolean,
    buttons: { type: string, link: string }[]
}

export const projects: projectType[] = [
    {
        title: "Fullstack & DevOps",
        org: "Hyperganic",
        // time: "Sep '22",
        date: new Date(2024, 12),
        description: "Freelance fullstack and QA engineer for a startup in Munich, Germany. Responsible for building and testing new features, and maintaining the existing codebase.",
        banner: hyperganicBanner,
        badges: [AWSBadge, NextJsBadge, RubyOnRailsBadge, ThreeJsBadge, AstroBadge, HerokuBadge],
        public: true,
        buttons: [
            { type: "WEBSITE", link: "https://www.hyperganic.com/" }
        ],
    },
    {
        title: "Tech Lead",
        org: "Capgemini",
        // time: "Sep '22",
        date: new Date(2024, 8),
        description: "Lead a team of developers to build and deploy version 3 of Abu Dhabi's TAMM app, with an AI assistant.",
        banner: tammBanner,
        badges: [ReactBadge],
        public: true,
        buttons: [
            { type: "WEBSITE", link: "https://www.tamm.abudhabi/" }
        ],
    },
    {
        title: "Project Lead",
        org: "BoonBot",
        // time: "Sep '22",
        date: new Date(2023, 8),
        description: "Started an AI SaaS startup that integrates with CRMs to help boost lead generation and sales.",
        banner: boonBanner,
        badges: [AWSBadge, NextJsBadge, PythonBadge],
        public: true,
        buttons: [
            { type: "WEBSITE", link: "https://www.boonbot.com/" }
        ],
    },
    {
        title: "Full Stack Developer",
        org: "Hangtime Basketball Academy",
        // time: "Sep '23",
        date: new Date(2023, 8),
        description: "As the sole developer, I built the web app and its infrastructure. The app is used to manage & track the academies' basketball league statistics.",
        banner: bigGameLeagueBanner,
        badges: [FirebaseBadge, NextJsBadge, VercelBadge],
        public: true,
        buttons: [
            { type: "WEBSITE", link: "https://biggameleague.com/" }
        ],
    },
    {
        title: "Research Collaborator",
        org: "Rochester Institute of Technology",
        // time: "May '21",
        date: new Date(2022, 4),
        description: "As the sole programmer in the research team, I implement algorithms to generate results theorized in our research about anonymous datasets.",
        banner: ritBanner,
        badges: [PythonBadge],
        public: false,
        buttons: [],
    },
    {
        title: "Graphic Designer",
        org: "BMWXV",
        // time: "Feb '21",
        date: new Date(2021, 1),
        description: "Uplifted this brand, creating their logos and posters.",
        banner: bmwxvBanner,
        badges: [PhotoshopBadge, IllustratorBadge],
        public: true,
        buttons: [
            { type: "WEBSITE", link: "https://www.instagram.com/bmwxv/" },
        ]
    },
    {
        title: "Mobile Dev",
        org: "Hong LLC",
        // time: "Oct '20",
        date: new Date(2020, 9),
        description: "Worked for a kickstart based in california as a fullstack developer with ReactNative and Django. Responsible for building, testing and deploying new features, designing UI and brand logos.",
        banner: privateBanner,
        badges: [ReactNativeBadge, DjangoBadge],
        public: false,
        buttons: []
    },
    {
        title: "Instructor",
        org: "STEP IT Academy",
        // time: "Aug '20",
        date: new Date(2020, 7),
        description: "Instructed two workshop classes for Blender and Unity. I prepared small demos for the attendees to recreate: build a game and their own 3D models.",
        banner: stepitBanner,
        badges: [BlenderBadge, UnityBadge],
        public: true,
        buttons: [
            { type: "WEBSITE", link: "https://dubai.itstep.org/" },
        ]
    },
    {
        title: "GREV",
        org: "HanaDigital",
        // time: "June '20",
        date: new Date(2020, 5),
        description: "A web app built on react that collects download statistics on github repository releases.",
        banner: grevBanner,
        badges: [ReactBadge, FirebaseBadge],
        public: true,
        buttons: [
            { type: "GITHUB", link: "https://github.com/HanaDigital/grev#readme" },
            { type: "WEBSITE", link: "https://hanadigital.github.io/grev/" },
        ]
    },
    {
        title: "Project Lead",
        org: "Delivery Boys",
        // time: "Apr '20",
        date: new Date(2020, 3),
        description: "Worked for a kickstart based in Dubai as a fullstack developer. I was responsible to lead a team of university students to build a cross platform solution for their deliveries.",
        banner: deliveryboysBanner,
        badges: [AngularBadge, FirebaseBadge],
        public: false,
        buttons: []
    },
    {
        title: "Graphic Designer",
        org: "Rochester Institute of Technology",
        // time: "Mar '19",
        date: new Date(2019, 2),
        description: "Worked as a freelance graphic designer to create two posters for events hosted by my university.",
        banner: ritBanner,
        badges: [PhotoshopBadge, IllustratorBadge],
        public: true,
        buttons: [
            { type: "WEBSITE", link: "https://www.rit.edu/dubai/" },
        ]
    },
    {
        title: "NovelScraper",
        org: "HanaDigital",
        // time: "Dec '18",
        date: new Date(2018, 11),
        description: "A desktop app built on Electron and Angular, to create a library of translated asian novels from the web.",
        banner: novelscraperBanner,
        badges: [AngularBadge, ElectronBadge],
        public: true,
        buttons: [
            { type: "GITHUB", link: "https://github.com/HanaDigital/NovelScraper#readme" },
        ]
    },
    {
        title: "Server Admin",
        org: "Eviction eSports",
        // time: "Apr '18",
        date: new Date(2018, 3),
        description: "Created and managed servers for their CSGO tournament. Setup broadcasting capabilities on the server for their live stream.",
        banner: evtBanner,
        badges: [TwitchBadge, LinuxBadge],
        public: true,
        buttons: [
            { type: "WEBSITE", link: "https://twitter.com/ggevt?lang=en" },
        ]
    },
];