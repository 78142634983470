import { FC, useState } from "react";
import "../styles/Projects.scss";
import Tilt from "react-parallax-tilt";
import { GithubIcon, PrivateIcon, WebsiteIcon } from "./Svg";
import { projects } from "../lib/projectList";

const Projects: FC = () => {
    const isSmallScreen = window.innerWidth < 750 ? true : false;
    const [loadButtonState, setLoadButtonState] = useState(isSmallScreen ? true : false);
    const [projectArray, setProjectArray] = useState(isSmallScreen ? projects.slice(0, 3) : [...projects]);

    const loadMoreProjects = () => {
        loadButtonState ? setProjectArray([...projects]) : setProjectArray(projects.slice(0, 3));
        setLoadButtonState(state => !state);
    }

    return (
        <div className="Projects">
            {
                projectArray.map(project => (
                    <div className="project" key={project.title + project.org + project.time}>
                        <div className="projectCard">
                            <div className="projectInfo">
                                <div className="projectHeader">
                                    <h1 className="projectTitle">{project.title}</h1>
                                    <p className="projectOrg">{project.org}</p>
                                </div>
                                <Tilt tiltMaxAngleX={5} tiltMaxAngleY={5} glareEnable={true} glareMaxOpacity={0.3} glareColor="white" glarePosition="all" scale={1.05}>
                                    <img className="projectCover" src={project.banner} alt="" />
                                </Tilt>
                                <div className="projectData">
                                    {project.time && <p className="projectTime">{project.time}</p>}
                                    {(!!project.badges && !!project.badges.length) &&
                                        <div className="projectTech">
                                            {project.badges.map((Badge, i) => <Badge key={i} />)}
                                        </div>
                                    }
                                </div>
                                <p className="projectDescription">{project.description}</p>
                            </div>
                            <div className="projectLinks">
                                {project.public && (
                                    <div className="projectLinks">
                                        {project.buttons.map(button => <ProjectButton {...button} key={button.link} />)}
                                    </div>
                                )}
                                {!project.public && (
                                    <div className="privateLink">
                                        <PrivateIcon className="privateButtonIcon" fill="white" />
                                        PRIVATE
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))
            }
            {
                isSmallScreen &&
                <div className="loadWrapper">
                    <button className="loadMore" onClick={loadMoreProjects}>{
                        loadButtonState ? "SHOW MORE" : "SHOW LESS"
                    }</button>
                </div>
            }
        </div>
    );
}

const ProjectButton: FC<{ type: string, link: string }> = ({ type, link }) => {
    const classType = type === "GITHUB" ? "projectGithub" : "projectWebsite";

    const original = type === "GITHUB" ? "#202020" : "#0327d8";
    const [hoverColor, setHoverColor] = useState(original);
    // const hoverColor = "#0327d8"

    const icon = type === "GITHUB"
        ? <GithubIcon className="projectButtonIcon" fill={hoverColor} />
        : <WebsiteIcon className="projectButtonIcon" fill={hoverColor} />;

    const handleMouseEnter = () => {
        setHoverColor("white");
    }
    const handleMouseLeave = () => {
        setHoverColor(original);
    }

    return (
        <a href={link} rel="noreferrer" target="_blank">
            <button className={"projectButton " + classType} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {icon}
                <p>{type}</p>
            </button>
        </a>
    );
}

export default Projects;